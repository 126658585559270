export * from "ce/entities/Engine/actionHelpers";
import { fetchConsumablePackagesInWorkspace } from "ee/actions/packageActions";
import { fetchAllWorkflowsForWorkspace } from "ee/actions/workflowActions";
import {
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "ee/constants/ReduxActionConstants";
import type { ExplorerURLParams } from "ee/pages/Editor/Explorer/helpers";
import type { DependentFeatureFlags } from "ee/selectors/engineSelectors";
import {
  getPageDependencyActions as CE_getPageDependencyActions,
  ActionParentEntityType as CE_ActionParentEntityType,
  CreateNewActionKey as CE_CreateNewActionKey,
} from "ce/entities/Engine/actionHelpers";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import type { EditConsolidatedApi } from "sagas/InitSagas";
import {
  convertToBaseParentEntityIdSelector,
  convertToPageIdSelector,
} from "selectors/pageListSelectors";

export const CreateNewActionKey = {
  ...CE_CreateNewActionKey,
  WORKFLOW: "workflowId",
  MODULE: "moduleId",
} as const;

export type CreateNewActionKeyInterface =
  (typeof CreateNewActionKey)[keyof typeof CreateNewActionKey];

export const ActionParentEntityType = {
  ...CE_ActionParentEntityType,
  WORKFLOW: "WORKFLOW",
  MODULE: "MODULE",
} as const;

export type ActionParentEntityTypeInterface =
  (typeof ActionParentEntityType)[keyof typeof ActionParentEntityType];

export const getPageDependencyActions = (
  currentWorkspaceId: string = "",
  featureFlags: DependentFeatureFlags = {},
  allResponses: EditConsolidatedApi,
  applicationId: string,
) => {
  const CE = CE_getPageDependencyActions(
    currentWorkspaceId,
    featureFlags,
    allResponses,
    applicationId,
  );
  const initActions = [
    ...CE.initActions,
    ...(featureFlags.showQueryModule
      ? [
          fetchConsumablePackagesInWorkspace({
            workspaceId: currentWorkspaceId,
            applicationId,
          }),
        ]
      : []),
    ...(featureFlags.showWorkflowFeature
      ? [fetchAllWorkflowsForWorkspace(currentWorkspaceId)]
      : []),
  ];

  const successActions = [
    ...CE.successActions,
    ...(featureFlags.showQueryModule
      ? [ReduxActionTypes.FETCH_CONSUMABLE_PACKAGES_IN_WORKSPACE_SUCCESS]
      : []),
    ...(featureFlags.showWorkflowFeature
      ? [ReduxActionTypes.FETCH_ALL_WORKFLOWS_FOR_WORKSPACE_SUCCESS]
      : []),
  ];

  const errorActions = [
    ...CE.errorActions,
    ...(featureFlags.showQueryModule
      ? [ReduxActionErrorTypes.FETCH_CONSUMABLE_PACKAGES_IN_WORKSPACE_ERROR]
      : []),
    ...(featureFlags.showWorkflowFeature
      ? [ReduxActionErrorTypes.FETCH_ALL_WORKFLOWS_FOR_WORKSPACE_ERROR]
      : []),
  ];

  return {
    initActions,
    successActions,
    errorActions,
  };
};

export enum APPSMITH_NAMESPACED_FUNCTIONS {
  assignRequest = "appsmith.workflows.assignRequest",
}

export const useParentEntityDetailsFromParams = (
  parentEntityIdProp: string,
  isInsideReconnectModal?: boolean,
) => {
  const baseParentEntityIdProp = useSelector((state) =>
    convertToBaseParentEntityIdSelector(state, parentEntityIdProp),
  );

  const urlParams = useParams<ExplorerURLParams>();
  const { basePageId, workflowId } = urlParams;
  const baseParentEntityIdQuery = basePageId || workflowId || "";
  const pageId = useSelector((state) =>
    convertToPageIdSelector(state, basePageId),
  );
  const parentEntityIdQuery = pageId || workflowId || "";
  const baseParentEntityId = isInsideReconnectModal
    ? baseParentEntityIdProp
    : baseParentEntityIdQuery;
  const parentEntityId = isInsideReconnectModal
    ? parentEntityIdProp
    : parentEntityIdQuery;
  const entityType: ActionParentEntityTypeInterface = !!workflowId
    ? ActionParentEntityType.WORKFLOW
    : ActionParentEntityType.PAGE;

  return { parentEntityId, baseParentEntityId, entityType };
};

export * from "ce/selectors/modulesSelector";
import type { AppState } from "ee/reducers";
import type { Module } from "ee/constants/ModuleConstants";
import type { Action } from "entities/Action";
import { createSelector } from "reselect";
import { countBy } from "lodash";
import type { ModuleInstanceEntitiesReducerState } from "ee/reducers/entityReducers/moduleInstanceEntitiesReducer";

const DEFAULT_INPUT_EVAL_VALUES = {};

/**
 * This is a duplicated selector from entitiesSelector and the reason for
 * duplication is the entitiesSelector is referencing the modulesSelector and
 * if the getDatasources is referenced from entitiesSelect, a cyclic dependency is
 * created. This has to be resolved more gracefully.
 *
 * Since appropriate typings are in place, if the structure changes then we would get typing error here.
 *  */
export const getDatasources = (state: AppState) => {
  return state.entities.datasources.list;
};

export const getAllModules = (state: AppState) => state.entities.modules;

export const getCurrentModuleId = (state: AppState) =>
  state.ui.editor.currentModuleId || "";

export const getCurrentModule = createSelector(
  getAllModules,
  getCurrentModuleId,
  (modules, moduleId): Module | undefined => modules[moduleId],
);

export const getModulePermissions = (state: AppState) => {
  const moduleId = getCurrentModuleId(state);
  const module = state.entities.modules[moduleId];

  return module?.userPermissions || [];
};

export const getModuleActions = (state: AppState) => state.entities.actions;

export const getModuleJSCollections = (state: AppState) =>
  state.entities.jsActions;

export const getModuleById = (
  state: AppState,
  moduleId: string,
): Module | undefined => state.entities.modules[moduleId];

export const getIsModuleFetchingEntities = (state: AppState) =>
  state.ui.editor.isModuleFetchingEntities;

export const getModulePublicAction = (
  state: AppState,
  moduleId: string,
): Action | undefined => {
  const action = state.entities.actions.find(
    (action) => action.config.moduleId === moduleId && action.config.isPublic,
  );

  return action ? action.config : undefined;
};

export const getModulePublicJSCollection = (
  state: AppState,
  moduleId: string,
) => {
  const action = state.entities.jsActions.find(
    (action) => action.config.moduleId === moduleId && action.config.isPublic,
  );

  return action ? action.config : undefined;
};

export const getIsModuleSaving = (state: AppState) => {
  return state.ui.editor.isModuleUpdating;
};

export const getIsModuleReferenceUpdating = (state: AppState) => {
  return state.ui.editor.isModuleReferenceUpdating;
};

export const getHasModuleUpdateError = (state: AppState) => {
  return state.ui.editor.hasModuleUpdateError;
};

export const getModuleInputsEvalValues = (state: AppState) =>
  state.evaluations.tree?.inputs || DEFAULT_INPUT_EVAL_VALUES;

export const getModuleInstanceActions = (
  state: AppState,
): ModuleInstanceEntitiesReducerState["actions"] =>
  state.entities.moduleInstanceEntities.actions;

export const getModuleInstanceJSCollections = (
  state: AppState,
): ModuleInstanceEntitiesReducerState["jsCollections"] =>
  state.entities.moduleInstanceEntities.jsCollections;

export const getModuleDSUsage = createSelector(
  getAllModules,
  getDatasources,
  (state: AppState, editorType: string) => editorType,
  (modules, datasources, editorType) => {
    const actionCount = countBy(Object.values(modules), "datasourceId");
    const actionDsMap: Record<string, string> = {};

    datasources.forEach((ds) => {
      actionDsMap[ds.id] = `No modules in this ${editorType}`;
    });
    Object.keys(actionCount).forEach((dsId) => {
      actionDsMap[dsId] = `${actionCount[dsId]} modules in this ${editorType}`;
    });

    return actionDsMap;
  },
);

export const getCurrentProcessingModuleReferences = (state: AppState) =>
  state.ui.editor.currentProcessingModuleReferences;

export const getHasPrivateEntity = createSelector(
  getModuleActions,
  getModuleJSCollections,
  getCurrentModule,
  (moduleActions, moduleJSCollections, currentModule) => {
    const hasPrivateActions = moduleActions.some(
      (action) =>
        action.config.moduleId === currentModule?.id && !action.config.isPublic,
    );
    const hasPrivateJSCollection = moduleJSCollections.some(
      (jsCollection) =>
        jsCollection.config.moduleId === currentModule?.id &&
        !jsCollection.config.isPublic,
    );

    return hasPrivateActions || hasPrivateJSCollection;
  },
);

export const getPrivateEntitiesNames = createSelector(
  getModuleActions,
  getModuleJSCollections,
  getCurrentModule,
  (moduleActions, moduleJSCollections, currentModule) => {
    const actionNames = moduleActions
      .filter(
        (a) => a.config.moduleId === currentModule?.id && a.config.isPublic,
      )
      .map((a) => a.config.name);
    const jsCollectionNames = moduleJSCollections
      .filter(
        (a) => a.config.moduleId === currentModule?.id && a.config.isPublic,
      )
      .map((a) => a.config.name);

    return [...jsCollectionNames, ...actionNames];
  },
);

export const getIsSettingUpModule = (state: AppState) =>
  state.ui.editor.isSettingUpModule;

export const getIsFetchingConsumableModules = (state: AppState) =>
  state.ui.editor.isFetchingConsumableModules;

export const getLintErrors = (state: AppState) => {
  return state.linting.errors;
};

export const getModuleReferences = (state: AppState) => {
  return state.ui.editor.moduleReferences;
};

export const getHasCyclicModuleReference = (state: AppState) => {
  return state.ui.editor.hasCyclicModuleReference;
};

export const getCurrentModuleReferences = createSelector(
  getModuleReferences,
  getCurrentModuleId,
  (references, moduleId) => {
    return references[moduleId];
  },
);

export const getActiveModuleActions = (state: AppState) =>
  state.ui.moduleActiveActions.activeActions;

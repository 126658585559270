export * from "ce/constants/ModuleInstanceConstants";
import type {
  ModuleInstance as CE_ModuleInstance,
  ModuleInstanceCreatorType,
} from "ce/constants/ModuleInstanceConstants";
import type { MODULE_TYPE, ModuleInput } from "./ModuleConstants";
import type { ActionResponse } from "api/ActionAPI";
import type { DynamicPath } from "utils/DynamicBindingUtils";
import type { ActionEntity } from "ee/entities/DataTree/types";

export interface ModuleInstanceInvalidState {
  packageName: string;
  moduleName: string;
  originPackageId: string;
}

export interface ModuleInstance extends CE_ModuleInstance {
  userPermissions: string[];
  sourceModuleId: string;
  contextId: string;
  contextType: ModuleInstanceCreatorType;
  jsonPathKeys: string[];
  dynamicBindingPathList?: DynamicPath[];
  invalids: string[];
  isValid: boolean;
  invalidState?: ModuleInstanceInvalidState;
  isDummy?: boolean;
  rootModuleInstanceId?: string;
  rootModuleInstanceName?: string; // Used for error logging
  sourceModuleName?: string; // Used for error logging
  // Flattened input definition from module. Used in autocomplete to pre-fill inputs.
  moduleInputs?: Record<string, ModuleInput>;
}
export interface QueryModuleInstance extends ModuleInstance {
  responseMeta: ActionEntity["responseMeta"];
  type: MODULE_TYPE.QUERY;
  data: unknown;
}
export interface JSModuleInstance extends ModuleInstance {
  type: MODULE_TYPE.JS;
}

export interface ModuleInstanceData {
  config: ModuleInstance;
  data: ActionResponse | undefined;
  isLoading: boolean;
}
export type ModuleInstanceDataState = ModuleInstanceData[];

export const DEFAULT_JS_PARAMS_KEY = "__$$DEFAULT_PARAMS$$__";

import type { Workflow as CE_Workflow } from "ce/constants/WorkflowConstants";

export interface WorkflowScheduleSpecInterface {
  cronExpressions: Array<string>;
  timeZoneName: string;
}

export enum WorkflowScheduleState {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export interface WorkflowScheduleInterface {
  scheduleSpec: WorkflowScheduleSpecInterface;
  triggerData: Record<string, unknown>;
  name: string;
  state: WorkflowScheduleState;
}

export interface Workflow extends CE_Workflow {
  schedules?: Record<string, WorkflowScheduleInterface>;
}

export type WorkflowMetadata = Pick<
  Workflow,
  "id" | "name" | "workspaceId" | "icon" | "color" | "modifiedAt" | "modifiedBy"
>;

export const DEFAULT_WORKFLOW_COLOR = "#9747FF1A";
export const DEFAULT_WORKFLOW_ICON = "workflows";
export const DEFAULT_WORKFLOW_PREFIX = "Untitled Workflow ";
export const WORKFLOW_SETTINGS_PANE_WIDTH = 250;

export enum WorkflowSettingsTabs {
  Trigger = "trigger",
  General = "general",
}

import { Button } from "@appsmith/ads";
import type { ControlType } from "constants/PropertyControlConstants";
import { getCarbonIntegrations } from "./getCarbonIntegrations";
import React, { Suspense, lazy } from "react";
import { type EmbeddingGenerators, type Integration } from "carbon-connect";
import { api } from "api/core";
import PluginsApi from "api/PluginApi";
import BaseControl, {
  type ControlProps,
} from "components/formControls/BaseControl";
import ErrorBoundary from "components/editorComponents/ErrorBoundry";
import AppsmithLogo from "assets/images/appsmith_logo_square.png";

const CarbonConnect = lazy(async () => import("./CarbonConnect"));

export interface CarbonButtonProps extends ControlProps {}

const loadingError = <div>Failed to load CarbonConnect</div>;

const fallbackButton = (
  <Button isLoading size="md" startIcon="add-more">
    Add data
  </Button>
);

interface CarbonButtonState {
  isLoaded: boolean;
  isCarbonModalOpen: boolean;
  integrations: Integration[];
  embeddingModel?: keyof typeof EmbeddingGenerators;
}

interface FetchAccessTokenResponse {
  trigger: {
    accessToken: string;
  };
}
class _CarbonButton extends BaseControl<CarbonButtonProps, CarbonButtonState> {
  state = {
    isLoaded: false,
    isCarbonModalOpen: false,
    integrations: [],
    embeddingModel: undefined,
  };

  async loadCarbonSettings() {
    const {
      AutoSyncedSourceTypes,
      EmbeddingGenerators,
      IntegrationName,
      ParsedTextFormats,
    } = await import("carbon-connect");

    this.setState({
      isLoaded: true,
      integrations: getCarbonIntegrations(
        AutoSyncedSourceTypes,
        EmbeddingGenerators,
        IntegrationName,
        ParsedTextFormats,
      ),
      embeddingModel: EmbeddingGenerators.OPENAI,
    });
  }

  componentDidMount(): void {
    this.loadCarbonSettings();
  }

  private async fetchAccessToken(
    datasourceId: string,
    workspaceId: string,
  ): Promise<{ access_token: string }> {
    const apiUrl = PluginsApi.defaultDynamicTriggerURL(datasourceId);
    const response = await api.post<FetchAccessTokenResponse>(apiUrl, {
      datasourceId,
      workspaceId,
      requestType: "GENERATE_RAG_SERVICE_ACCESS_TOKEN",
      displayType: "DROP_DOWN",
    });

    if (!response.responseMeta.success) {
      return Promise.reject(response.responseMeta.error);
    }

    return { access_token: response.data.trigger.accessToken };
  }

  render() {
    const { datasourceId, workspaceId } = this.props;

    if (!this.state.isLoaded || !datasourceId || !workspaceId) {
      return null;
    }

    return (
      <ErrorBoundary fallback={loadingError}>
        <Suspense fallback={fallbackButton}>
          <Button
            onClick={() => {
              this.setState({
                isCarbonModalOpen: true,
              });
            }}
            size="md"
            startIcon="add-more"
          >
            Add data
          </Button>
          {this.state.isCarbonModalOpen && (
            <CarbonConnect
              backButtonText="Close"
              brandIcon={AppsmithLogo}
              embeddingModel={this.state.embeddingModel}
              enabledIntegrations={this.state.integrations}
              open={this.state.isCarbonModalOpen}
              orgName="Appsmith"
              setOpen={(isOpen) =>
                this.setState({ isCarbonModalOpen: isOpen as boolean })
              }
              tokenFetcher={async () =>
                this.fetchAccessToken(datasourceId, workspaceId)
              }
            />
          )}
        </Suspense>
      </ErrorBoundary>
    );
  }

  getControlType(): ControlType {
    return "CARBON_BUTTON";
  }
}

export const CarbonButton = _CarbonButton;

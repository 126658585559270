import { FocusEntity } from "navigation/FocusEntity";
import type { FocusElementsConfigList } from "sagas/FocusRetentionSaga";
import { FocusElement, FocusElementConfigType } from "navigation/FocusElements";
import { getSelectedDatasourceId } from "ee/navigation/FocusSelectors";
import { setSelectedDatasource } from "ee/navigation/FocusSetters";
import { getFirstDatasourceId } from "selectors/datasourceSelectors";
import {
  getFormData,
  getPluginActionConfigSelectedTab,
  setExtraFormData,
  setPluginActionEditorSelectedTab,
} from "PluginActionEditor/store";
import { EDITOR_TABS } from "constants/QueryEditorConstants";
import { PluginPackageName } from "entities/Action";
import {
  API_EDITOR_TABS,
  POST_BODY_FORMAT_OPTIONS,
} from "PluginActionEditor/constants/CommonApiConstants";

export default {
  [FocusEntity.DATASOURCE_LIST]: [
    {
      type: FocusElementConfigType.URL,
      name: FocusElement.SelectedDatasource,
      selector: getSelectedDatasourceId,
      setter: setSelectedDatasource,
      defaultValue: getFirstDatasourceId,
    },
  ],
  [FocusEntity.QUERY]: [
    {
      type: FocusElementConfigType.Redux,
      name: FocusElement.PluginActionConfigTabs,
      selector: getPluginActionConfigSelectedTab,
      setter: setPluginActionEditorSelectedTab,
      defaultValue: EDITOR_TABS.QUERY,
      subTypes: {
        [PluginPackageName.GRAPHQL]: {
          defaultValue: API_EDITOR_TABS.BODY,
        },
        [PluginPackageName.REST_API]: {
          defaultValue: API_EDITOR_TABS.HEADERS,
        },
      },
    },
    {
      type: FocusElementConfigType.Redux,
      name: FocusElement.PluginActionFormData,
      selector: getFormData,
      setter: setExtraFormData,
      defaultValue: {
        label: POST_BODY_FORMAT_OPTIONS.NONE,
        value: POST_BODY_FORMAT_OPTIONS.NONE,
      },
    },
  ],
} as FocusElementsConfigList;

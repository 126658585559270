export * from "ce/selectors/moduleInstanceSelectors";
import type {
  ModuleInstance,
  ModuleInstanceId,
} from "ee/constants/ModuleInstanceConstants";
import type { AppState } from "ee/reducers";
import type { Action } from "entities/Action";
import type { ActionData } from "ee/reducers/entityReducers/actionsReducer";
import type { QueryModuleInstanceEntity } from "ee/entities/DataTree/types";
import { MODULE_TYPE } from "ee/constants/ModuleConstants";
import type { JSCollection } from "entities/JSCollection";
import type {
  ModuleInstanceAction,
  ModuleInstanceEntitiesReducerState,
  ModuleInstanceJSCollection,
  ModuleInstanceJSCollectionData,
} from "ee/reducers/entityReducers/moduleInstanceEntitiesReducer";
import { createSelector } from "reselect";
import { getAllModules } from "./modulesSelector";
import { keyBy } from "lodash";

const DEFAULT_SAVING_STATUS = {
  isSaving: false,
  error: false,
};

const DEFAULT_RUNNING_STATUS = {
  isRunning: false,
};

const DEFAULT_INPUT_EVAL_VALUES = {};

type ModuleInstanceEntity =
  | ActionData
  | ModuleInstanceJSCollectionData
  | ModuleInstance;

export const getAllModuleInstances = (
  state: AppState,
): Record<ModuleInstanceId, ModuleInstance> => state.entities.moduleInstances;

const getAllModuleInstanceEntities = (
  state: AppState,
): ModuleInstanceEntitiesReducerState => state.entities.moduleInstanceEntities;

export const getModuleInstanceById = (
  state: AppState,
  id: string,
): ModuleInstance | undefined => state.entities.moduleInstances[id];

export const getIsModuleInstanceNameSavingStatus = (
  state: AppState,
  moduleInstanceId: string,
) => {
  return (
    state.ui.moduleInstancePane.nameSavingStatus[moduleInstanceId] ||
    DEFAULT_SAVING_STATUS
  );
};

export const getModuleInstancePublicEntity = (
  state: AppState,
  moduleInstanceId: string,
  type: MODULE_TYPE | undefined,
): Action | JSCollection | undefined => {
  if (!!type && type === MODULE_TYPE.QUERY) {
    return getModuleInstancePublicAction(state, moduleInstanceId);
  } else if (!!type && type === MODULE_TYPE.JS) {
    return getModuleInstancePublicJSCollectionData(state, moduleInstanceId)
      ?.config;
  }
};

export const getModuleInstancePublicAction = (
  state: AppState,
  moduleInstanceId: string,
): Action | undefined => {
  const action = state.entities.moduleInstanceEntities.actions.find(
    (action: ActionData) => {
      return (
        action.config.moduleInstanceId === moduleInstanceId &&
        action.config.isPublic
      );
    },
  );

  return action?.config;
};

export const getModuleInstancePublicJSCollectionData = (
  state: AppState,
  moduleInstanceId: string,
) => {
  const jsCollectionData: ModuleInstanceJSCollectionData | undefined =
    state.entities.moduleInstanceEntities.jsCollections.find(
      (js: ModuleInstanceJSCollectionData) => {
        return (
          js.config.moduleInstanceId === moduleInstanceId && js.config.isPublic
        );
      },
    );

  return jsCollectionData;
};

export const getAllReferencedModuleInstances = (
  state: AppState,
): ModuleInstance[] => {
  return state.entities.moduleInstanceEntities.moduleInstances;
};

export const getAllEnhancedReferencedModuleInstance = createSelector(
  getAllModuleInstances,
  getAllModules,
  getAllReferencedModuleInstances,
  (moduleInstances, modules, referencedModuleInstances) => {
    const moduleInstancesById = keyBy(moduleInstances, "id");
    const modulesById = keyBy(modules, "id");

    return referencedModuleInstances.map((referencedModuleInstance) => {
      const rootModuleInstance =
        moduleInstancesById[
          referencedModuleInstance?.rootModuleInstanceId || ""
        ];
      const sourceModule = modulesById[referencedModuleInstance.sourceModuleId];

      return {
        ...referencedModuleInstance,
        rootModuleInstanceName: rootModuleInstance?.name || "",
        sourceModuleName: sourceModule?.name || "",
      };
    });
  },
);

export const getIsJSModuleInstanceActionExecuting = (
  state: AppState,
  moduleInstanceId?: string,
  actionId?: string | null,
) => {
  if (!moduleInstanceId || !actionId) return false;

  const jsCollectionData: ModuleInstanceJSCollectionData | undefined =
    state.entities.moduleInstanceEntities.jsCollections.find(
      (js: ModuleInstanceJSCollectionData) => {
        return (
          js.config.moduleInstanceId === moduleInstanceId && js.config.isPublic
        );
      },
    );

  return jsCollectionData?.isExecuting?.[actionId] || false;
};

export const getPrivateEntityInstanceMapping = createSelector(
  getAllModuleInstances,
  getAllModuleInstanceEntities,
  (rootModulesInstances, moduleInstanceEntities) => {
    const mapping: Record<string, string> = {};

    const referencedModuleInstances = keyBy(
      moduleInstanceEntities.moduleInstances,
      "id",
    );

    const processEntity = (entity: ModuleInstanceEntity) => {
      const { entityName, moduleInstanceId } = extractEntityDetails(entity);
      const moduleInstance = resolveModuleInstance(
        rootModulesInstances,
        referencedModuleInstances,
        moduleInstanceId,
      );

      if (moduleInstance) {
        mapping[entityName] = moduleInstance.name;
      }
    };

    const extractEntityDetails = (entity: ModuleInstanceEntity) => {
      let moduleInstanceId = "";
      let entityName = "";

      if ("config" in entity) {
        moduleInstanceId = entity.config.moduleInstanceId || "";
        entityName = entity.config.name || "";
      } else {
        moduleInstanceId = entity.rootModuleInstanceId || "";
        entityName = entity.name || "";
      }

      return { moduleInstanceId, entityName };
    };

    const resolveModuleInstance = (
      // TODO: Fix this the next time the file is edited
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      rootModulesInstances: any,
      // TODO: Fix this the next time the file is edited
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      referencedModuleInstances: any,
      moduleInstanceId: string,
    ) => {
      const referencedModuleInstance =
        referencedModuleInstances[moduleInstanceId];
      const rootModuleInstance = rootModulesInstances[moduleInstanceId];

      return referencedModuleInstance
        ? rootModulesInstances[
            referencedModuleInstance.rootModuleInstanceId || ""
          ]
        : rootModuleInstance;
    };

    const entities = [
      ...moduleInstanceEntities.actions,
      ...moduleInstanceEntities.jsCollections,
      ...moduleInstanceEntities.moduleInstances,
    ];

    entities.forEach(processEntity);

    return mapping;
  },
);

export const getModuleInstanceActiveJSActionId = (
  state: AppState,
  jsCollectionId: string,
): string | null => {
  const jsCollection = state.entities.moduleInstanceEntities.jsCollections.find(
    (jsCollectionData: ModuleInstanceJSCollectionData) =>
      jsCollectionData.config.id === jsCollectionId,
  );

  return jsCollection?.activeJSActionId ?? null;
};

export const getModuleInstanceActionResponse = (
  state: AppState,
  actionId: string,
) => {
  const action = state.entities.moduleInstanceEntities.actions.find(
    ({ config }: { config: Action }) => config.id === actionId,
  );

  return action?.data;
};

export const getIsModuleInstanceRunningStatus = (
  state: AppState,
  moduleInstanceId: string,
) =>
  state.ui.moduleInstancePane.runningStatus[moduleInstanceId] ||
  DEFAULT_RUNNING_STATUS;

export const getModuleInstanceInputsEvalValues = (
  state: AppState,
  moduleInstanceName: string,
) => {
  const moduleInstance = state.evaluations.tree[
    moduleInstanceName
  ] as QueryModuleInstanceEntity;

  return moduleInstance?.inputs || DEFAULT_INPUT_EVAL_VALUES;
};

export const getModuleInstanceActionById = (
  state: AppState,
  actionId: string,
): ModuleInstanceAction | undefined => {
  const actionData = state.entities.moduleInstanceEntities.actions.find(
    ({ config }: { config: Action }) => config.id === actionId,
  );

  return actionData?.config;
};

export const getModuleInstanceJSCollectionById = (
  state: AppState,
  jsCollectionId: string,
): ModuleInstanceJSCollection | undefined => {
  const jsCollectionData =
    state.entities.moduleInstanceEntities.jsCollections.find(
      ({ config }: { config: JSCollection }) => config.id === jsCollectionId,
    );

  return jsCollectionData?.config;
};

export const getModuleInstancesByModuleId = createSelector(
  getAllModuleInstances,
  (state: AppState, moduleId: string) => moduleId,
  (moduleInstances, moduleId) => {
    return Object.values(moduleInstances).filter(
      (instance) => instance.contextId === moduleId && !instance.isDummy,
    );
  },
);
